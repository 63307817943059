export default Object.freeze({
  /**
   * Error is undetermined
   */
  unknown: 'unknownError',
  /**
   * An internal api error.
   */
  api: 'apiError',
  /**
   *  One or more of the input parameters failed validation.
   */
  validation: 'validationError',
  /**
   * An authorisation or authentication error.
   */
  security: 'securityError',
  /**
   * Requested resource does not exist.
   */
  notFound: 'notFoundError',
})
