import GenericFileModel from '@/models/file/genericFileModel'
import { fileToBase64String } from '@/services/file/Base64Convert'
import { avatarColourSets } from '@/helpers/color-helpers'

/**
 * Get file icon based on a file's MIME type
 * @param {string} fileType File MIME type
 */
const getIconByMIMEType = function (fileType) {
  if (!fileType) return 'mdi-file-question'

  switch (fileType) {
    case 'application/pdf':
      return 'mdi-file-document-outline'
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.oasis.opendocument.text':
      return 'mdi-file-word-outline'
    case 'image/png':
    case 'image/webp':
    case 'image/jpeg':
    case 'image/gif':
      return 'mdi-file-image-outline'
    default:
      return 'mdi-file-document-outline'
  }
}

/**
 * @param {string} fileType File MIME type
 */
const getFileIconColourByMIMEType = function (fileType) {
  if (!fileType) return 'mdi-file-question'

  switch (fileType) {
    case 'application/pdf':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.oasis.opendocument.text':
      return avatarColourSets.blue
    case 'image/png':
    case 'image/webp':
    case 'image/jpeg':
    case 'image/gif':
      return avatarColourSets.ateam_purple
    default:
      return avatarColourSets.orange
  }
}

/**
 * Extracts the extension from a filename. If no extension is found, returns filename
 * @param {string} filename
 */
const getExtensionFromFileName = function (filename) {
  if (typeof filename !== 'string')
    throw Error('Invalid data type for filename (Expected String)')

  try {
    return (
      filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
      filename
    )
  } catch (e) {
    throw Error(
      `Unable to extract extension from file name provided: ${filename}`
    )
  }
}

/**
 * Returns a file size in a friendly, human readable format.
 * Adapted from: https://stackoverflow.com/a/18650828
 * @param {number} fileSizeBytes
 */
const getFriendlyFileSize = function (fileSizeBytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (fileSizeBytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(fileSizeBytes) / Math.log(1024)))
  return Math.round(fileSizeBytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

/**
 * Converts a file into a base64 string and returns it with the file's name
 * @param {File} file
 * @returns
 */
const processFile = async function (file) {
  const base64FileData = await fileToBase64String(file.file)

  return Promise.resolve(
    new GenericFileModel({
      base64FileData,
      fileName: file.name,
    })
  )
}

/**
 * Processes a list of files by converting them into base64 string, preparing them
 * for upload
 * @param {Array<File>} files
 * @returns {Promise<Array<GenericFileModel>>}
 */
const processFilesForUpload = async function (files) {
  if (!files || files.length === 0) return Promise.resolve([])
  return Promise.all(files.map(processFile))
}

const extractFileNameFromContentDisposition = function (contentDisposition) {
  const patternMatchString = 'filename='
  const possibleFileNames = contentDisposition
    .split(';')
    .map((string) => string.trim())
    .filter((trimmedString) => trimmedString.startsWith(patternMatchString))
  if (possibleFileNames.length > 0) {
    return possibleFileNames[0]
      .substring(patternMatchString.length)
      .replaceAll('"', '')
  }
  return null
}

export {
  getIconByMIMEType,
  getExtensionFromFileName,
  getFriendlyFileSize,
  processFilesForUpload,
  extractFileNameFromContentDisposition,
  getFileIconColourByMIMEType,
}
