import { extractFileNameFromContentDisposition } from '@helpers/file-helpers'
import { getType } from 'mime'
import { fail, success } from '@/helpers/result-helper.js'
import { isCacheFresh } from '@/helpers/cache-helpers'
import DurationUnits from '@/constants/date/DurationUnits.js'
import toast from '@/services/toasts/index.js'
import dayjs from '@/services/date/index.js'
import { processFilesForUpload } from '@/helpers/file-helpers'
import CertificationDetailsVM from '@/models/certification/certificationDetailsVM'
import CertificationListItemVM from '@/models/certification/certificationListItemVM'
import HttpHeaders from '@/constants/api/HttpHeaders'
import { isNonEmptyArray } from '@/helpers/array-helpers'
import { CertificationActionErrorMessageFactory } from '@/services/certifications/CertificationActionErrorMessageFactory'

const getDefaultState = () => {
  return {
    certifications: null,
    certificationTypes: null,
    loadingCount: 0,
    loadingCertificationsCount: 0,
    loadingCertificationTypesCount: 0,
    loadingDetailsCount: 0,
    crudLoadingCount: 0,
    loadingFile: [],
    loadingDeleteCount: 0,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  state,
  getters: {
    moduleName: () => 'certifications',
    certifications: (state) => state.certifications?.list,
    certificationTypes: (state) => state.certificationTypes?.list,
    getTypeById: (state) => (typeId) =>
      state.certificationTypes?.list?.find((type) => type.id === typeId),
    isLoading: (state) => state.loadingCount > 0,
    isLoadingCertifications: (state) => state.loadingCertificationsCount > 0,
    isLoadingCertificationTypes: (state) =>
      state.loadingCertificationTypesCount > 0,
    isLoadingCRUD: (state) => state.crudLoadingCount > 0,
    isLoadingDetails: (state) => state.loadingDetailsCount > 0,
    isLoadingDelete: (state) => state.loadingDeleteCount > 0,
    isDownloadingFile: (state, getters) => (accessGui) =>
      !!getters.getDownloadFileLoadingState(accessGui),
  },
  mutations: {
    START_LOADING(state) {
      state.loadingCount++
    },
    FINISH_LOADING(state) {
      state.loadingCount--
    },
    START_LOADING_CERTIFICATIONS(state) {
      state.loadingCertificationsCount++
    },
    FINISH_LOADING_CERTIFICATIONS(state) {
      state.loadingCertificationsCount--
    },
    START_LOADING_CERTIFICATION_TYPES(state) {
      state.loadingCertificationTypesCount++
    },
    FINISH_LOADING_CERTIFICATION_TYPES(state) {
      state.loadingCertificationTypesCount--
    },
    START_LOADING_CRUD(state) {
      state.crudLoadingCount++
    },
    FINISH_LOADING_CRUD(state) {
      state.crudLoadingCount--
    },
    START_LOADING_DETAILS(state) {
      state.loadingDetailsCount++
    },
    FINISH_LOADING_DETAILS(state) {
      state.loadingDetailsCount--
    },
    START_LOADING_DELETE(state) {
      state.loadingDeleteCount++
    },
    FINISH_LOADING_DELETE(state) {
      state.loadingDeleteCount--
    },
    START_DOWNLOADING_FILE(state, accessGui) {
      const alreadyExists = state.loadingFile.includes(accessGui)
      if (alreadyExists) return
      state.loadingFile.push(accessGui)
    },
    FINISH_DOWNLOADING_FILE(state, accessGui) {
      const foundIndex = state.loadingFile.findIndex(
        (cachedAccessGuid) => cachedAccessGuid === accessGui
      )
      if (foundIndex < 0) return
      state.loadingFile.splice(foundIndex, 1)
    },
    SET_CERTIFICATIONS(state, certifications) {
      state.certifications = {
        list: certifications,
        lastUpdated: dayjs(),
        isDirty: false,
      }
    },
    SET_CERTIFICATION_TYPES(state, certificationTypes) {
      state.certificationTypes = {
        list: certificationTypes,
        lastUpdated: dayjs(),
        isDirty: false,
      }
    },
    SET_CERTIFICATIONS_DIRTY(state) {
      if (!state.certifications) return // Will prevent an exception if certs aren't initialised
      state.certifications.isDirty = true
    },
    REMOVE_CERTIFICATION(state, certificationId) {
      if (!isNonEmptyArray(state.certifications?.list)) return

      const foundIndex = state.certifications.list.findIndex(
        (cert) => cert.id === certificationId
      )
      // If not found, reload summary list
      if (foundIndex < 0) {
        if (!state.certifications) return
        state.certifications.isDirty = true
      }

      state.certifications.list.splice(foundIndex, 1)
    },
    INSERT_CERTIFICATION(state, certification) {
      const index = state.certifications.list.findIndex(
        (item) => item.typeId === certification.typeId
      )

      if (index > -1) {
        state.certifications.list.splice(
          index,
          1,
          new CertificationListItemVM(certification)
        )
      } else {
        state.certifications.list.push(
          new CertificationListItemVM(certification)
        )
      }
    },
    CLEAR_STORE(state) {
      // Resets store to default state
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    /**
     * @param {*} param0
     * @param {Boolean} forceRefresh
     * @returns {Promise<ResultDTO>}
     */
    async getCertificationsList({ commit, getters, dispatch }, forceRefresh) {
      // 1. Check if we already have the certs cached
      if (
        isCacheFresh({
          cacheDuration: 1,
          durationUnits: DurationUnits.HOUR,
          lastUpdated: state.certifications?.lastUpdated,
          forceRefresh,
          isDirty: state.certifications?.isDirty,
        })
      )
        return success({ data: getters.certifications })

      // 2. Fetch certs from api
      commit('START_LOADING_CERTIFICATIONS')
      try {
        const response = await this.$api.certifications.get()
        const certListItems = response?.data.map(
          (cert) => new CertificationListItemVM(cert)
        )
        commit('SET_CERTIFICATIONS', certListItems)
        return success({ data: getters.certifications })
      } catch (ex) {
        toast.error(this.$i18n.t('certifications.errorLoadCertifications'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_CERTIFICATIONS')
      }
    },
    /**
     * @param {*} param0
     * @param {Boolean} forceRefresh
     * @returns {Promise<ResultDTO>}
     */
    async getCertificationTypes({ commit, getters, dispatch }, forceRefresh) {
      // 1. Check if we already have the data cached
      if (
        isCacheFresh({
          cacheDuration: 1,
          durationUnits: DurationUnits.HOUR,
          lastUpdated: state.certificationTypes?.lastUpdated,
          forceRefresh,
        })
      )
        return success({ data: getters.certificationTypes })

      // 2. Fetch data from api
      commit('START_LOADING_CERTIFICATION_TYPES')

      try {
        const response = await this.$api.certifications.getCertTypes()
        commit('SET_CERTIFICATION_TYPES', response.data)
        return success({ data: getters.certificationTypes })
      } catch (ex) {
        toast.error(this.$i18n.t('certifications.errorLoadCertificationTypes'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_CERTIFICATION_TYPES')
      }
    },
    async getCertificationTypeById({ commit, dispatch }, certTypeId) {
      commit('START_LOADING_CERTIFICATION_TYPES')
      try {
        const response = await this.$api.certifications.getCertTypeById(
          certTypeId
        )
        return response
      } catch (ex) {
        toast.error(this.$i18n.t('certifications.errorLoadCertificationTypes'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_LOADING_CERTIFICATION_TYPES')
      }
    },
    /**
     * Inserts a certification. Will initialise certifications list if it hasn't
     * been already.
     * @param {*} param0
     * @param {*} certification
     */
    insertCertification({ commit, getters }, certification) {
      if (!isNonEmptyArray(getters.certifications)) {
        commit('SET_CERTIFICATIONS', [])
      }

      commit('INSERT_CERTIFICATION', certification)

      commit('SET_CERTIFICATIONS_DIRTY')
    },
    /**
     * @param {*} param0
     * @param {*} certificationToUpload
     * @returns {Promise<ResultDTO>}
     */
    async uploadCertification(
      { commit, dispatch, getters },
      { files, certificationData, complianceItemId = null }
    ) {
      const payload = {
        files: await processFilesForUpload(files),
        certificationData,
        complianceItemId,
      }

      commit('START_LOADING_CRUD')
      try {
        const response = await this.$api.certifications.post('', payload)

        const certTypeFound = isNonEmptyArray(getters.certificationTypes)
          ? getters.certificationTypes.find(
              (type) =>
                type.id === payload.certificationData.certificationTypeId
            )
          : null

        const certTypeTitle = certTypeFound ? certTypeFound.title : ''

        await dispatch('insertCertification', {
          id: response.data.id,
          expireStatus: response.data.expireStatus,
          dateExpiry: payload.certificationData.dateExpiry,
          dateObtained: payload.certificationData.dateObtained,
          certRefNo: payload.certificationData.certRefNo,
          isVerified: false,
          firstParentId: response.data.id,
          title: certTypeTitle,
          typeId: payload.certificationData.certificationTypeId,
        })

        return response
      } catch (ex) {
        const errorResponse = await dispatch('logException', ex, { root: true })
        toast.error(errorResponse.message)
        return fail({ error: errorResponse })
      } finally {
        commit('FINISH_LOADING_CRUD')
      }
    },
    /**
     * Retrieves certification details by id
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Number} certificationId
     * @returns {Promise<ResultDTO>}
     */
    async getCertificationDetails({ commit, dispatch }, certificationId) {
      commit('START_LOADING_DETAILS')
      try {
        const result = await this.$api.certifications.get(certificationId)
        result.data = new CertificationDetailsVM(result.data)
        return result
      } catch (ex) {
        const error = await dispatch('logException', ex, { root: true })

        if (ex.response.status === 404) {
          error.message = this.$i18n.t(
            'certifications.view.recordNotFoundError'
          )
        }

        toast.error(error.message)
        return fail({ error, statusCode: ex.response.status })
      } finally {
        commit('FINISH_LOADING_DETAILS')
      }
    },
    /**
     * Downloads blob of the certification file
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {{certificationId: Number, accessGui: String}} payload
     * @returns {Promise<ResultDTO>} Blob of the certification file
     */
    async downloadCertificationFile(
      { commit, dispatch },
      { certificationId, accessGui }
    ) {
      commit('START_DOWNLOADING_FILE', accessGui)
      try {
        const response = await this.$api.certifications.downloadFile({
          certificationId,
          accessGui,
        })
        const fileName = extractFileNameFromContentDisposition(
          response.headers[HttpHeaders.contentDisposition]
        )
        const blob = new Blob([response.data], {
          type: getType(fileName),
        })
        return success({ data: blob })
      } catch (ex) {
        toast.error(this.$i18n.t('error.unableToLoadFile'))
        return fail({
          error: await dispatch('logException', ex, { root: true }),
        })
      } finally {
        commit('FINISH_DOWNLOADING_FILE', accessGui)
      }
    },
    /**
     * Deletes a certification
     * @param {{commit: Function, dispatch: Function}} vuexContext
     * @param {Number} certificationId
     * @returns {Promise<ResultDTO>}
     */
    async deleteCertification({ commit, dispatch }, certificationId) {
      commit('START_LOADING_DELETE')
      try {
        const response = await this.$api.certifications.delete(certificationId)

        commit('REMOVE_CERTIFICATION', certificationId)

        toast.success(this.$i18n.t('certifications.deleteSuccessfulText'))

        return response
      } catch (ex) {
        const error = await dispatch('logException', ex, { root: true })
        toast.error(
          CertificationActionErrorMessageFactory({
            actionName: 'deleteCertification',
            error,
            i18n: this.$i18n,
          })
        )
        return fail({ error, statusCode: ex.response.status })
      } finally {
        commit('FINISH_LOADING_DELETE')
      }
    },
    /**
     * Resets store to default state.
     */
    clear({ commit }) {
      commit('CLEAR_STORE')
    },
  },
}
